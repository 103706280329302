// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';
import { getAccesToken } from '../../helpers/authUtils'
import {

    GET_TALLERES,
    GET_TALLERES_SUCCESS,
    GET_TALLERES_FAIL,

    GET_REPUESTEROS,
    GET_REPUESTEROS_SUCCESS,
    GET_REPUESTEROS_FAIL,

    GET_COMPANIAS,
    GET_COMPANIAS_SUCCESS,
    GET_COMPANIAS_FAIL,

    GET_DISPONIBILIDAD,
    GET_DISPONIBILIDAD_SUCCESS,
    GET_DISPONIBILIDAD_FAIL,

    POST_NEW_SINIESTRO,
    POST_NEW_SINIESTRO_SUCCESS,
    POST_NEW_SINIESTRO_FAIL,

    GET_SINIESTROS,
    GET_SINIESTROS_SUCCESS,
    GET_SINIESTROS_FAIL,


    GET_TIPO_REPUESTO,
    GET_TIPO_REPUESTO_SUCCESS,
    GET_TIPO_REPUESTO_FAIL,

    GET_PERITOS,
    GET_PERITOS_SUCCESS,
    GET_PERITOS_FAIL,

    apiGetDisponibilidad,
    apiGetTalleres,
    apiGetCompanias,
    apiGetRepuesteros,
    apiPostNewSiniestro,
    apiGetSiniestros,
    apiGetTipoRepuesto,
    apiGetPeritos

} from './constants';

import {


    getTalleresApi,
    getRepuesterosApi,
    getCompaniasApi,
    getDisponibilidadApi,
    postNuevoSiniestroApi,
    getTipoRepuestoApi,

    getTalleresSuccess,
    getRepuesterosSuccess,
    getCompaniasSuccess,
    getDisponibilidadSuccess,
    postNuevoSiniestroSuccess,
    getSiniestrosSuccess,
    getTipoRepuestoSuccess,
    getPeritosSuccess,

    getTalleresFail,
    getRepuesterosFail,
    getCompaniasFail,
    getDisponibilidadFail,
    postNuevoSiniestroFail,
    getSiniestrosFail,
    getTipoRepuestoFail,
    getPeritosFail,


} from './actions';



function* getTalleres() {
    // console.log('get talleres')

    const options = {
        body: null,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + getAccesToken().token, },
    };

    try {
        const response = yield call(fetchJSON, apiGetTalleres, options);
        yield put(getTalleresSuccess(response));
    } catch (error) {
        //console.log('error', error)
        yield put(getTalleresFail(error));
    }
}

function* getRepuesteros() {
    //  console.log('get getRepuesteros')

    const options = {
        body: null,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + getAccesToken().token, },
    };

    try {
        const response = yield call(fetchJSON, apiGetRepuesteros, options);
        yield put(getRepuesterosSuccess(response));
    } catch (error) {
        //console.log('error', error)
        yield put(getRepuesterosFail(error));
    }
}

function* getCompanias() {
    //  console.log('get getCompanias')

    const options = {
        body: null,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + getAccesToken().token, },
    };

    try {
        const response = yield call(fetchJSON, apiGetCompanias, options);
        yield put(getCompaniasSuccess(response));
    } catch (error) {
        //console.log('error', error)
        yield put(getCompaniasFail(error));
    }
}

function* getDisponibilidad() {
    // console.log('get getDisponibilidad')

    const options = {
        body: null,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + getAccesToken().token, },
    };

    try {
        const response = yield call(fetchJSON, apiGetDisponibilidad, options);
        yield put(getDisponibilidadSuccess(response));
    } catch (error) {
        //console.log('error', error)
        yield put(getDisponibilidadFail(error));
    }
}

function* postNuevoSiniestro(data) {

    const options = {
        body: JSON.stringify(data.payload),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + getAccesToken().token, },
    };

    try {
        const response = yield call(fetchJSON, apiPostNewSiniestro, options);
        yield put(postNuevoSiniestroSuccess(response));
    } catch (error) {
        //console.log('error', error)
        yield put(postNuevoSiniestroFail(error));
    }
}

function* getSiniestros() {

    const options = {
        body: null,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + getAccesToken().token, },
    };

    try {
        const response = yield call(fetchJSON, apiGetSiniestros, options);
        yield put(getSiniestrosSuccess(response));
    } catch (error) {
        yield put(getSiniestrosFail(error));
    }
}



function* getPeritos() {

    const options = {
        body: null,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + getAccesToken().token, },
    };

    try {
        const response = yield call(fetchJSON, apiGetPeritos, options);
        yield put(getPeritosSuccess(response));
    } catch (error) {
        yield put(getPeritosFail(error));
    }
}

function* getTipoRepuesto() {
    //  console.log('get getRepuesteros')

    const options = {
        body: null,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + getAccesToken().token, },
    };

    try {
        const response = yield call(fetchJSON, apiGetTipoRepuesto, options);
        yield put(getTipoRepuestoSuccess(response));
    } catch (error) {
        //console.log('error', error)
        yield put(getTipoRepuestoFail(error));
    }
}





export function* watchGetPeritos() {
    yield takeEvery(GET_PERITOS, getPeritos);
}
export function* watchGetPeritosSuccess() {
    yield takeEvery(GET_PERITOS_SUCCESS, getPeritosSuccess);
}
export function* watchGetPeritosFail() {
    yield takeEvery(GET_PERITOS_FAIL, getPeritosFail);
}





export function* watchGetSiniestros() {
    yield takeEvery(GET_SINIESTROS, getSiniestros);
}
export function* watchGetSiniestrosSuccess() {
    yield takeEvery(GET_SINIESTROS_SUCCESS, getSiniestrosSuccess);
}
export function* watchGetSiniestrosFail() {
    yield takeEvery(GET_SINIESTROS_FAIL, getSiniestrosFail);
}


export function* watchGetTalleres() {
    yield takeEvery(GET_TALLERES, getTalleres);
}
export function* watchGetTalleresSuccess() {
    yield takeEvery(GET_TALLERES_SUCCESS, getTalleresSuccess);
}
export function* watchGetTalleresFail() {
    yield takeEvery(GET_TALLERES_FAIL, getTalleresFail);
}


export function* watchGetRepuesteros() {
    yield takeEvery(GET_REPUESTEROS, getRepuesteros);
}
export function* watchGetRepuesterosSuccess() {
    yield takeEvery(GET_REPUESTEROS_SUCCESS, getRepuesterosSuccess);
}
export function* watchGetRepuesterosFail() {
    yield takeEvery(GET_REPUESTEROS_FAIL, getRepuesterosFail);
}


export function* watchGetCompanias() {
    yield takeEvery(GET_COMPANIAS, getCompanias);
}
export function* watchGetCompaniasSuccess() {
    yield takeEvery(GET_COMPANIAS_SUCCESS, getCompaniasSuccess);
}
export function* watchGetCompaniasFail() {
    yield takeEvery(GET_COMPANIAS_FAIL, getCompaniasFail);
}


export function* watchGetDisponibilidad() {
    yield takeEvery(GET_DISPONIBILIDAD, getDisponibilidad);
}
export function* watchGetDisponibilidadSuccess() {
    yield takeEvery(GET_DISPONIBILIDAD_SUCCESS, getDisponibilidadSuccess);
}
export function* watchGetDisponibilidadFail() {
    yield takeEvery(GET_DISPONIBILIDAD_FAIL, getDisponibilidadFail);
}


export function* watchPostNewSiniestro() {
    yield takeEvery(POST_NEW_SINIESTRO, postNuevoSiniestro);
}
export function* watchPostNewSiniestroSuccess() {
    yield takeEvery(POST_NEW_SINIESTRO_SUCCESS, postNuevoSiniestroSuccess);
}
export function* watchPostNewSiniestroFail() {
    yield takeEvery(POST_NEW_SINIESTRO_FAIL, postNuevoSiniestroFail);
}


export function* watchGetTipoRepuesto() {
    yield takeEvery(GET_TIPO_REPUESTO, getTipoRepuesto);
}
export function* watchGetTipoRepuestoSuccess() {
    yield takeEvery(GET_TIPO_REPUESTO_SUCCESS, getTipoRepuestoSuccess);
}
export function* watchGetTipoRepuestoFail() {
    yield takeEvery(GET_TIPO_REPUESTO_FAIL, getTipoRepuestoFail);
}



function* siniestrosSaga() {
    yield all([



        fork(watchGetPeritos),
        fork(watchGetPeritosSuccess),
        fork(watchGetPeritosFail),

        fork(watchGetTipoRepuesto),
        fork(watchGetTipoRepuestoSuccess),
        fork(watchGetTipoRepuestoFail),

        fork(watchGetTalleres),
        fork(watchGetTalleresSuccess),
        fork(watchGetTalleresFail),

        fork(watchGetRepuesteros),
        fork(watchGetRepuesterosSuccess),
        fork(watchGetRepuesterosFail),

        fork(watchGetCompanias),
        fork(watchGetCompaniasSuccess),
        fork(watchGetCompaniasFail),

        fork(watchGetDisponibilidad),
        fork(watchGetDisponibilidadSuccess),
        fork(watchGetDisponibilidadFail),

        fork(watchPostNewSiniestro),
        fork(watchPostNewSiniestroSuccess),
        fork(watchPostNewSiniestroFail),

        fork(watchGetSiniestros),
        fork(watchGetSiniestrosSuccess),
        fork(watchGetSiniestrosFail),
    ]);
}

export default siniestrosSaga;
