// @flow
import {
    GET_TALLERES,
    GET_TALLERES_SUCCESS,
    GET_TALLERES_FAIL,

    GET_REPUESTEROS,
    GET_REPUESTEROS_SUCCESS,
    GET_REPUESTEROS_FAIL,

    GET_COMPANIAS,
    GET_COMPANIAS_SUCCESS,
    GET_COMPANIAS_FAIL,

    GET_DISPONIBILIDAD,
    GET_DISPONIBILIDAD_SUCCESS,
    GET_DISPONIBILIDAD_FAIL,


    POST_NEW_SINIESTRO,
    POST_NEW_SINIESTRO_SUCCESS,
    POST_NEW_SINIESTRO_FAIL,

    GET_SINIESTROS,
    GET_SINIESTROS_SUCCESS,
    GET_SINIESTROS_FAIL,


    GET_PERITOS,
    GET_PERITOS_SUCCESS,
    GET_PERITOS_FAIL,


    GET_TIPO_REPUESTO,
    GET_TIPO_REPUESTO_SUCCESS,
    GET_TIPO_REPUESTO_FAIL,

} from './constants';


//GET /api/1.0/{workspace}/cases

export const getTalleres = () => ({
    type: GET_TALLERES
});
export const getTalleresSuccess = (data) => ({
    type: GET_TALLERES_SUCCESS,
    data: data
});
export const getTalleresFail = (error) => ({
    type: GET_TALLERES_FAIL,
    error: error
});


//GET /api/1.0/{workspace}/cases

export const getRepuesteros = () => ({
    type: GET_REPUESTEROS
});
export const getRepuesterosSuccess = (data) => ({
    type: GET_REPUESTEROS_SUCCESS,
    data: data
});
export const getRepuesterosFail = (error) => ({
    type: GET_REPUESTEROS_FAIL,
    error: error
});


export const getCompanias = () => ({
    type: GET_COMPANIAS
});
export const getCompaniasSuccess = (data) => ({
    type: GET_COMPANIAS_SUCCESS,
    data: data
});
export const getCompaniasFail = (error) => ({
    type: GET_COMPANIAS_FAIL,
    error: error
});


export const getDisponibilidad = () => ({
    type: GET_DISPONIBILIDAD
});
export const getDisponibilidadSuccess = (data) => ({
    type: GET_DISPONIBILIDAD_SUCCESS,
    data: data
});
export const getDisponibilidadFail = (error) => ({
    type: GET_DISPONIBILIDAD_FAIL,
    error: error
});




export const postNuevoSiniestro = (data) => ({
    type: POST_NEW_SINIESTRO,
    payload: data
});
export const postNuevoSiniestroSuccess = (data) => ({
    type: POST_NEW_SINIESTRO_SUCCESS,
    data: data.siniestro
});
export const postNuevoSiniestroFail = (error) => ({
    type: POST_NEW_SINIESTRO_FAIL,
    error: error
});






export const getPeritos = () => ({
    type: GET_PERITOS
});
export const getPeritosSuccess = (data) => ({
    type: GET_PERITOS_SUCCESS,
    data: data
});
export const getPeritosFail = (error) => ({
    type: GET_PERITOS_FAIL,
    error: error
});






export const getSiniestros = () => ({
    type: GET_SINIESTROS
});
export const getSiniestrosSuccess = (data) => ({
    type: GET_SINIESTROS_SUCCESS,
    data: data
});
export const getSiniestrosFail = (error) => ({
    type: GET_SINIESTROS_FAIL,
    error: error
});



export const getTipoRepuesto = () => ({
    type: GET_TIPO_REPUESTO
});
export const getTipoRepuestoSuccess = (data) => ({
    type: GET_TIPO_REPUESTO_SUCCESS,
    data: data
});
export const getTipoRepuestoFail = (error) => ({
    type: GET_TIPO_REPUESTO_FAIL,
    error: error
});