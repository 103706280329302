// @flow
import {
    GET_TALLERES,
    GET_TALLERES_SUCCESS,
    GET_TALLERES_FAIL,

    GET_REPUESTEROS,
    GET_REPUESTEROS_SUCCESS,
    GET_REPUESTEROS_FAIL,

    GET_COMPANIAS,
    GET_COMPANIAS_SUCCESS,
    GET_COMPANIAS_FAIL,

    GET_DISPONIBILIDAD,
    GET_DISPONIBILIDAD_SUCCESS,
    GET_DISPONIBILIDAD_FAIL,

    POST_NEW_SINIESTRO,
    POST_NEW_SINIESTRO_SUCCESS,
    POST_NEW_SINIESTRO_FAIL,


    GET_SINIESTROS,
    GET_SINIESTROS_SUCCESS,
    GET_SINIESTROS_FAIL,

    GET_TIPO_REPUESTO,
    GET_TIPO_REPUESTO_SUCCESS,
    GET_TIPO_REPUESTO_FAIL,

    GET_PERITOS,
    GET_PERITOS_SUCCESS,
    GET_PERITOS_FAIL,
} from './constants';


const INIT_STATE = {

    getTalleresLoading: false,
    getTalleresSuccess: false,
    getTalleresFail: false,
    talleres: [],

    getCompaniasLoading: false,
    getCompaniasSuccess: false,
    getCompaniasFail: false,
    companias: [],

    getRepuesterosLoading: false,
    getRepuesterosSuccess: false,
    getRepuesterosFail: false,
    repuesteros: [],

    getDisponibilidadLoading: false,
    getDisponibilidadSuccess: false,
    getDisponibilidadFail: false,
    disponibilidad: [],

    postNewSiniestroLoading: false,
    postNewSiniestroSuccess: false,
    postNewSiniestroFail: false,
    siniestro: [],

    getSiniestrosLoading: false,
    getSiniestrosSuccess: false,
    getSiniestrosFail: false,
    siniestrosList: [],

    getTipoRepuestosLoading: false,
    getTipoRepuestosSuccess: false,
    getTipoRepuestosFail: false,
    tipoRepuestos: [],

    getPeritosLoading: false,
    getPeritosSuccess: false,
    getPeritosFail: false,
    peritos: []


};


const Cases = (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_TALLERES:
            return { ...state, getTalleresLoading: true };
        case GET_TALLERES_SUCCESS:
            return { ...state, getTalleresLoading: false, getTalleresSuccess: true, talleres: action.data };
        case GET_TALLERES_FAIL:
            return { ...state, getTalleresLoading: false, getTalleresSuccess: false, getTalleresFail: action.data };


        case GET_COMPANIAS:
            return { ...state, getCompaniasLoading: true };
        case GET_COMPANIAS_SUCCESS:
            return { ...state, getCompaniasLoading: false, getCompaniasSuccess: true, companias: action.data };
        case GET_COMPANIAS_FAIL:
            return { ...state, getCompaniasLoading: false, getCompaniasSuccess: false, getCompaniasFail: action.data };

        case GET_REPUESTEROS:
            return { ...state, getRepuesterosLoading: true };
        case GET_REPUESTEROS_SUCCESS:
            return { ...state, getRepuesterosLoading: false, getRepuesterosSuccess: true, repuesteros: action.data };
        case GET_REPUESTEROS_FAIL:
            return { ...state, getRepuesterosLoading: false, getRepuesterosSuccess: false, getRepuesterosFail: action.data };


        case GET_DISPONIBILIDAD:
            return { ...state, getDisponibilidadLoading: true };
        case GET_DISPONIBILIDAD_SUCCESS:
            return { ...state, getDisponibilidadLoading: false, getDisponibilidadSuccess: true, disponibilidad: action.data };
        case GET_DISPONIBILIDAD_FAIL:
            return { ...state, getDisponibilidadLoading: false, getDisponibilidadSuccess: false, getDisponibilidadFail: action.data };


        case POST_NEW_SINIESTRO:
            return { ...state, postNewSiniestroLoading: true };
        case POST_NEW_SINIESTRO_SUCCESS:
            return { ...state, postNewSiniestroLoading: false, postNewSiniestroSuccess: true, siniestro: action.data };
        case POST_NEW_SINIESTRO_FAIL:
            return { ...state, postNewSiniestroLoading: false, postNewSiniestroSuccess: false, postNewSiniestroFail: action.data };


        case GET_SINIESTROS:
            return { ...state, getSiniestrosLoading: true, getSiniestrosSuccess: false };
        case GET_SINIESTROS_SUCCESS:
            return { ...state, getSiniestrosLoading: false, getSiniestrosSuccess: true, siniestrosList: action.data };
        case GET_SINIESTROS_FAIL:
            return { ...state, getSiniestrosLoading: false, getSiniestrosSuccess: false, getSiniestrosFail: action.data };




        case GET_PERITOS:
            return { ...state, getPeritosLoading: true, getPeritosSuccess: false };
        case GET_PERITOS_SUCCESS:
            return { ...state, getPeritosLoading: false, getPeritosSuccess: true, peritos: action.data };
        case GET_PERITOS_FAIL:
            return { ...state, getPeritosLoading: false, getPeritosSuccess: false, getPeritosFail: action.data };





        case GET_TIPO_REPUESTO:
            return { ...state, getTipoRepuestosLoading: true };
        case GET_TIPO_REPUESTO_SUCCESS:
            return { ...state, getTipoRepuestosLoading: false, getTipoRepuestosSuccess: true, tipoRepuestos: action.data };
        case GET_TIPO_REPUESTO_FAIL:
            return { ...state, getTipoRepuestosLoading: false, getTipoRepuestosSuccess: false, getTipoRepuestosFail: action.data };



        default:
            return { ...state };
    }
};

export default Cases;
