/* siniestros */

export const GET_TALLERES = 'GET_TALLERES';
export const GET_TALLERES_SUCCESS = 'GET_TALLERES_SUCCESS';
export const GET_TALLERES_FAIL = 'GET_TALLERES_FAIL';

export const GET_REPUESTEROS = 'GET_REPUESTEROS';
export const GET_REPUESTEROS_SUCCESS = 'GET_REPUESTEROS_SUCCESS';
export const GET_REPUESTEROS_FAIL = 'GET_REPUESTEROS_FAIL';

export const GET_COMPANIAS = 'GET_COMPANIAS';
export const GET_COMPANIAS_SUCCESS = 'GET_COMPANIAS_SUCCESS';
export const GET_COMPANIAS_FAIL = 'GET_COMPANIAS_FAIL';

export const GET_DISPONIBILIDAD = 'GET_DISPONIBILIDAD';
export const GET_DISPONIBILIDAD_SUCCESS = 'GET_DISPONIBILIDAD_SUCCESS';
export const GET_DISPONIBILIDAD_FAIL = 'GET_DISPONIBILIDAD_FAIL';

export const POST_NEW_SINIESTRO = 'POST_NEW_SINIESTRO';
export const POST_NEW_SINIESTRO_SUCCESS = 'POST_NEW_SINIESTRO_SUCCESS';
export const POST_NEW_SINIESTRO_FAIL = 'POST_NEW_SINIESTRO_FAIL';

export const GET_SINIESTROS = 'GET_SINIESTROS';
export const GET_SINIESTROS_SUCCESS = 'GET_SINIESTROS_SUCCESS';
export const GET_SINIESTROS_FAIL = 'GET_SINIESTROS_FAIL';

export const GET_TIPO_REPUESTO = 'GET_TIPO_REPUESTO';
export const GET_TIPO_REPUESTO_SUCCESS = 'GET_TIPO_REPUESTO_SUCCESS';
export const GET_TIPO_REPUESTO_FAIL = 'GET_TIPO_REPUESTO_FAIL';

export const GET_PERITOS = 'GET_PERITOS';
export const GET_PERITOS_SUCCESS = 'GET_PERITOS_SUCCESS';
export const GET_PERITOS_FAIL = 'GET_PERITOS_FAIL';


// API'S
let enviroment = 'https://api.estudiofabrizio.com.ar/'

// GET
export const apiGetTalleres = enviroment + 'api/talleres';
export const apiGetRepuesteros = enviroment + 'api/repuesteros';
export const apiGetCompanias = enviroment + 'api/companias';
export const apiGetDisponibilidad = enviroment + 'api/disponibilidad';
export const apiGetTipoRepuesto = enviroment + 'api/tipo-repuestos';
export const apiGetPeritos = enviroment + 'api/peritos';

// POST
export const apiPostNewSiniestro = enviroment + 'api/siniestro';
export const apiGetSiniestros = enviroment + 'api/siniestros';