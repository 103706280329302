/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {
    
    return fetch(url, options)
        .then(response => {
            console.log('response',response)
            if (!response.ok) {
                throw Error(response.status);
            }
            return response.json();
        })
        .then(json => {
            console.log('json',json)
            return json;
          
        })  
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export { fetchJSON };
